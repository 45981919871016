<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="stations"
      sort-by="s_number"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Alla stationer</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Ny Station
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <!-- TODO: Validation with vee-validate  -->
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedStation.s_number"
                        label="Stationsnummer"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedStation.s_lat_even"
                        label="Latitud (Jämn)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedStation.s_long_even"
                        label="Longitud (Jämn)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedStation.s_lat_odd"
                        label="Latitud (Udda)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedStation.s_long_odd"
                        label="Longitud (Udda)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="editedStation.s_rebus"
                        label="Rebus"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="editedStation.s_help1"
                        label="Första hjälprebus"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="editedStation.s_help2"
                        label="Andra hjälprebus"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="editedStation.s_help3"
                        label="Tredje hjälprebus"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="editedStation.s_facit"
                        label="Facit"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="editedStation.s_token"
                        label="Stationskod"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="generateToken(6)"
                >
                  Slumpa kod
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Avbryt
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Spara
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Är du säker på att du vill radera stationen?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteStationConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editStation(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteStation(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../../utils/notification'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Stationsnummer',
        align: 'start',
        sortable: false,
        value: 's_number',
      },
      { text: 'Latitud (Jämn)', value: 's_lat_even' },
      { text: 'Longitud (Jämn)', value: 's_long_even' },
      { text: 'Latitud (Udda)', value: 's_lat_odd' },
      { text: 'Longitud (Udda)', value: 's_long_odd' },
      { text: 'Rebus', value: 's_rebus' },
      { text: 'Hjälprebus #1', value: 's_help1' },
      { text: 'Hjälprebus #2', value: 's_help2' },
      { text: 'Hjälprebus #3', value: 's_help3' },
      { text: 'Facit', value: 's_facit' },
      { text: 'Stationskod', value: 's_token' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    stations: [],
    editedIndex: -1,
    editedStation: {
      s_number: 1,
      s_lat_even: '',
      s_long_even: '',
      s_lat_odd: '',
      s_long_odd: '',
      s_rebus: '',
      s_help1: '',
      s_help2: '',
      s_help3: '',
      s_facit: '',
      s_token: ''
    },
    defaultStation: {
      s_number: 1,
      s_lat_even: '',
      s_long_even: '',
      s_lat_odd: '',
      s_long_odd: '',
      s_rebus: '',
      s_help1: '',
      s_help2: '',
      s_help3: '',
      s_facit: '',
      s_token: ''
    },
  }),

  computed: {
    ...mapGetters(["notification"]),
    formTitle () {
      return this.editedIndex === -1 ? 'Ny Station' : 'Redigera Station'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(["getAllStations", "createStation", "updateOneStation", "deleteOneStation", "clearNotifications"]),
    async initialize () {
      const stations = await this.getAllStations()
      // Reset
      this.stations = []
      // Filter teams
      stations.forEach((station) => {
        this.stations.push({
          s_number: station.s_number,
          s_lat_even: station.s_lat_even,
          s_long_even: station.s_long_even,
          s_lat_odd: station.s_lat_odd,
          s_long_odd: station.s_long_odd,
          s_rebus: station.s_rebus,
          s_help1: station.s_help1,
          s_help2: station.s_help2,
          s_help3: station.s_help3,
          s_facit: station.s_facit,
          s_token: station.s_token
        })
      })
    },
    editStation (station) {
      this.editedIndex = this.stations.indexOf(station)
      this.editedStation = Object.assign({}, station)
      this.dialog = true
    },
    deleteStation (station) {
      this.editedIndex = this.stations.indexOf(station)
      this.editedStation = Object.assign({}, station) // ??
      this.dialogDelete = true
    },
    async deleteStationConfirm () {
      const station = this.stations[this.editedIndex]
      await this.deleteOneStation(station.s_number)

      // Notification
      const success = notify(this)
      if (success) {
        await this.initialize()
        this.closeDelete()
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedStation = Object.assign({}, this.defaultStation)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedStation = Object.assign({}, this.defaultStation)
        this.editedIndex = -1
      })
    },
    async save () {
      if (this.editedIndex > -1) {
        // UPDATE
        await this.updateOneStation(this.editedStation)
      } else {
        // NEW
        await this.createStation(this.editedStation)
      }
      // Notification
      const success = notify(this)
      if (success) {
        await this.initialize()
        this.close()
      }
    },
    // Return a random token from the set [a-zA-Z0-9]
    generateToken(size) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for ( var i = 0; i < size; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      this.editedStation.s_token = result
    }
  }
}
</script>

<style>

</style>